$(document).ready(function() {
    init_banner();
    init_header();

    // Animation library
    sal();

    var has_barba = $('body').attr('data-barba');

    if(has_barba && has_barba == 'wrapper') {
        barba.init({
            debug: false,
            prefetchIgnore: true
        });

        barba.hooks.before(function() {
            $('.navbar-collapse').collapse('hide');
            $('.foldout-menu').collapse('hide');
        });

        barba.hooks.enter(function() {
            $(window).scrollTop(0);
        });

        barba.hooks.after(function() {
            init_banner();
            init_header();

            // Animation library
            sal();

            var myLazyLoad = new LazyLoad();

            if (typeof ga === 'function') {
                ga('send', 'pageview', location.pathname);
            }
        });
    }
});