function init_header() {
    evaluate_active_state();

    var scrollTimeout;
    var throttle = 10;
    var $mobile_message = $('#mobile-top-message');

    $(window).on('scroll', function () {
        if (!scrollTimeout) {
            scrollTimeout = setTimeout(function () {
                if($(document).scrollTop() > $('#header-top').height()) {
                    if(!$mobile_message.is(":hidden")) {
                        $mobile_message.addClass('mobile-top-message-visible');
                    }
                }
                else {
                    $mobile_message.removeClass('mobile-top-message-visible');
                }
                scrollTimeout = null;
            }, throttle);
        }
    });
}

function evaluate_active_state() {
    $('header .navbar a.active').removeClass('active');
    var page = $('#current_page_slug').val();

    if(page.length > 1) {
        $('header .navbar a[href*="'+page+'"]').addClass('active');
    }
}