// Lazy loading for supported browsers
// Apply lazybg class
// Put url in data-lazybg attr

if (!('IntersectionObserver' in window) ||
    !('IntersectionObserverEntry' in window) ||
    !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {

        document.addEventListener("DOMContentLoaded", function() {
            var lazyObjects = [].slice.call(document.querySelectorAll(".lazybg, .slider-lazybg"));
            lazyObjects.forEach(function(lazyObject) {
                if(!(lazyObject.dataset.lazybg == '')){
                    bgsrc = lazyObject.dataset.lazybg;
                    lazyObject.style.backgroundImage = 'url('+bgsrc+')';
                    lazyObject.classList.remove("lazybg");
                    lazyObject.dataset.lazybg = '';
                }
            });
        });
}

else {
    let lazyObjectObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
            if (entry.isIntersecting) {
                let lazyObject = entry.target;
                if(!(lazyObject.dataset.lazybg == '')){
                    bgsrc = lazyObject.dataset.lazybg;
                    lazyObject.style.backgroundImage = 'url('+bgsrc+')';
                    lazyObject.classList.remove("lazybg");
                    lazyObject.dataset.lazybg = '';
                    lazyObjectObserver.unobserve(lazyObject);
                }
            }
        });
    },{ rootMargin: "0px 0px 0px 0px" });

    document.addEventListener("DOMContentLoaded", function() {
        var lazyObjects = [].slice.call(document.querySelectorAll(".lazybg"));
        lazyObjects.forEach(function(lazyObject) {
            lazyObjectObserver.observe(lazyObject);
        });
    });

    $(window).bind("load", function() {
        var lazySliderObjects = [].slice.call(document.querySelectorAll(".slider-lazybg"));
        lazySliderObjects.forEach(function(lazyObject) {
            if(!(lazyObject.dataset.lazybg == '')){
                bgsrc = lazyObject.dataset.lazybg;
                lazyObject.style.backgroundImage = 'url('+bgsrc+')';
                lazyObject.classList.remove("slider-lazybg");
                lazyObject.dataset.lazybg = '';
                lazyObjectObserver.unobserve(lazyObject);
            }
        });
    });
}